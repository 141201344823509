function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000); // Convert to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function aggregateData(data, interval) {
  const intervalInSeconds = {
    "1m": 60,
    "5m": 300,
    "15m": 900,
    "30m": 1800,
    "1h": 3600,
    "4h": 14400,
    "1d": 86400,
    "1w": 604800,
    "1M": 2592000,
  };
  const aggregatedData = [];
  let currentIntervalStart = data[0].time;
  let intervalData = []; // Initialize outside the loop

  for (let i = 0; i < data.length; i++) {
    const currentDataPoint = data[i];
    const timeDiff = currentDataPoint.time - currentIntervalStart;
    if (timeDiff >= intervalInSeconds[interval]) {
      if (intervalData.length > 0) {
        const aggregatedPoint = {
          time: currentIntervalStart,
          open: intervalData[0].open,
          high: Math.max(...intervalData.map((d) => d.high)),
          low: Math.min(...intervalData.map((d) => d.low)),
          close: intervalData[intervalData.length - 1].close,
        };
        aggregatedData.push(aggregatedPoint);
      }
      currentIntervalStart = currentDataPoint.time;
      intervalData = []; // Clear for the next interval
    }
    intervalData.push(currentDataPoint);
  }

  // Handle the last interval
  if (intervalData.length > 0) {
    const aggregatedPoint = {
      time: currentIntervalStart,
      open: intervalData[0].open,
      high: Math.max(...intervalData.map((d) => d.high)),
      low: Math.min(...intervalData.map((d) => d.low)),
      close: intervalData[intervalData.length - 1].close,
    };
    aggregatedData.push(aggregatedPoint);
  }

  return aggregatedData;
}

function aggregateDataByInterval(oneMinuteData) {
  const aggregatedData = {};

  const intervals = ["15m", "30m", "1h", "4h"];

  intervals.forEach((interval) => {
    aggregatedData[interval] = aggregateData(oneMinuteData, interval);
  });

  return aggregatedData;
}

function convertOHLCToLineData(ohlcData) {
  const lineData = ohlcData.map((dataPoint) => ({
    time: dataPoint.time,
    value: (dataPoint.close + dataPoint.open) / 2,
  }));
  return lineData;
}

export {
  formatTimestamp,
  aggregateData,
  aggregateDataByInterval,
  convertOHLCToLineData,
};
