// eslint-disable-next-line
import { createChart, ColorType, CrosshairMode } from "lightweight-charts";

import React, { useEffect, useRef, useState } from "react";
import { Colors } from "../Colors";
import { useLocation } from "react-router-dom";
import { socketRef } from "./socket";
import { convertOHLCToLineData } from "../devdata/const/marketDataFunctions";
import { SocketData } from "../devdata/const/interface";
import {
  get1m,
  get5m,
  get15m,
  get30m,
  get60m,
  get1Day,
  get1Week,
  get1Month,
  requestInitialChartData,
  requestRecurringChartData,
  getRecurringChartData,
  getInitialChartData,
  unsubscribeFromRoom,
} from "./socket";

// const finTervals = ["1", "5", "15", "30", "60", "D", "W", "M"];
// Translating to 1m, 5m, 15m, 30m, 1h, 4h, 1d, 1w, 1M (for the client)

function Chart({ props }) {
  const chartOuterContainerRef = useRef();
  const chartContainerRef = useRef();
  const location = useLocation();
  const theme = props.theme;
  const symbol = props.symbol;
  const size = props.size;
  const zoomIndex = props.zoomIndex;
  const interval = props.interval;
  const originalInterval = props.originalInterval;
  const type = props.type;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const requesttime = Date.now();

  const finnhub = require("finnhub");
  const api_key = finnhub.ApiClient.instance.authentications["api_key"];
  api_key.apiKey = "cr9ltdpr01qp5d50l9ugcr9ltdpr01qp5d50l9v0"; // Replace with your actual API key
  const finnhubClient = new finnhub.DefaultApi();

  var updateRecurringChartBool = false;
  var numberOfBarsPointer = 0;
  var fetching = false;
  // const [realTimeData, setRealTimeData] = useState();
  var realTimeData = [];
  var totalData = [];
  // let stockData = {};
  // let stockLineData = {};
  // Object mapping intervals to their corresponding functions
  const getPromptedFunction = {
    1: get1m,
    5: get5m,
    15: get15m,
    30: get30m,
    60: get60m,
    D: get1Day,
    W: get1Week,
    M: get1Month,
  };

  const intervalInSeconds = {
    1: 60, // 1 minute
    5: 5 * 60,
    15: 15 * 60,
    30: 30 * 60,
    60: 60 * 60,
    D: 60 * 60 * 24,
    W: 60 * 60 * 24 * 7,
    M: 60 * 60 * 24 * 30, // Assuming 30 days in a month
  };

  var timer = null;

  // Utilities

  // Function to get the appropriate function based on the interval
  const getFunction = (interval) => {
    return getPromptedFunction[interval];
  };

  useEffect(() => {
    setTimeout(() => {
      getFunction(originalInterval)(symbol, type);
      requestInitialChartData(symbol, originalInterval, type, 100);
    }, 250);
  }, []);

  const timeToTzWithDateFnsTz = (originalTime) => {
    const newGMT30 = originalTime + 19800;

    return {
      zonedTime: newGMT30,
      elapsedTime: 0,
    };
  };

  const roundDownToInterval = (interval) => {
    var msInterval = interval * 60000; // Convert interval to milliseconds for numeric intervals

    if (isNaN(interval)) {
      // Handle string intervals
      // console.log(interval, intervalInSeconds[interval]);
      msInterval = intervalInSeconds[interval];
    }

    var toReturn = Math.floor(requesttime / msInterval) * msInterval;
    // console.log("Returning", toReturn);

    // return toReturn;

    console.log(requesttime, "returning this");
    return requesttime;
    // return Math.floor(requesttime / msInterval) * msInterval;
  };

  const mergeSortRemoveDuplicates = (arr1, arr2) => {
    // Step 1: Merge the two arrays
    const mergedArray = [...arr1, ...arr2];

    // Step 2: Sort the merged array by the 'time' property in ascending order
    mergedArray.sort((a, b) => a.time - b.time);

    // Step 3: Remove duplicates based on the 'time' property
    const uniqueArray = mergedArray.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.time === item.time)
    );

    return uniqueArray;
  };

  const setChart = (data, chartToBuild, type, socketRef) => {
    console.log("Recieved inital data", data);
    const chartData = data;

    const stockLineData = convertOHLCToLineData(chartData);

    const askedData = type === "candlestick" ? chartData : stockLineData;
    chartToBuild.setData(askedData);
    realTimeData = askedData;
    numberOfBarsPointer = askedData.length;

    socketRef.off("initialChartData");
  };

  const updateRecurringChart = (
    data,
    chartToBuild,
    type,
    socketRef,
    updateRecurringChartBool,
    chart
  ) => {
    console.log("Recieved recurring data", data);
    const chartData = data;

    const stockLineData = convertOHLCToLineData(chartData);

    const askedData = type === "candlestick" ? chartData : stockLineData;

    realTimeData = mergeSortRemoveDuplicates(realTimeData, askedData);

    chartToBuild.setData(realTimeData);

    // chart.timescale().scrollToPosition(numberOfBarsPointer);
    numberOfBarsPointer = realTimeData.length;
    updateRecurringChartBool = false;
  };

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
        handleScroll: chart.timeScale().scrollToRealTime(),
      });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: {
          type: ColorType.Solid,
          color:
            theme === "dark" ? Colors.dark_background : Colors.light_background,
        },
        textColor: theme === "dark" ? Colors.dark_text : Colors.light_text,
        fontSize: size ? size : 12,
      },

      grid: {
        horzLines: {
          color: theme === "dark" ? Colors.dark_border : Colors.light_border,
        },
        vertLines: {
          color: theme === "dark" ? Colors.dark_border : Colors.light_border,
        },
      },
      width: chartContainerRef.current.clientWidth,
      height: window.innerHeight,
      // height: '200',
    });

    chart.applyOptions({
      crosshair: {
        // Change mode from default 'magnet' to 'normal'.
        // Allows the crosshair to move freely without snapping to datapoints
        mode: CrosshairMode.Normal,

        // Vertical crosshair line (showing Date in Label)
        vertLine: {
          color: "#C3BCDB44",
          // style: LineStyle.Solid,
          labelBackgroundColor: "#9B7DFF",
        },

        // Horizontal crosshair line (showing Price in Label)
        horzLine: {
          color: "#9B7DFF",
          labelBackgroundColor: "#9B7DFF",
        },
      },
    });

    chart.timeScale().applyOptions({
      timeVisible: true,
      borderVisible: true,
      barSpacing: zoomIndex ? zoomIndex : 10,
    });

    chart.timeScale().scrollToPosition(5);

    const chartToBuild =
      type === "candlestick"
        ? chart.addCandlestickSeries({
            upColor: Colors.profit,
            borderUpColor: Colors.profit,
            wickUpColor: Colors.profit,
            downColor: Colors.loss,
            borderDownColor: Colors.loss,
            wickDownColor: Colors.loss,
            priceLineStyle: 1,
            baseLineStyle: 1,
            priceFormat: {
              precision: 6,
              minMove: 0.000001,
            },
          })
        : chart.addAreaSeries({
            lastValueVisible: false, // hide the last value marker for this series
            crosshairMarkerVisible: true, // hide the crosshair marker for this series
            lineColor: "rgba(72, 113, 247, 0.8)", // hide the line
            topColor: "rgba(72, 113, 247, 0.5)",
            bottomColor: "rgba(72, 113, 247, 0.2)",
          });

    // Fetch data on component mount
    const visibleRange = chart.timeScale().getVisibleLogicalRange();

    // For initial fetch
    socketRef.on("initialChartData", (data) => {
      setChart(data.data, chartToBuild, type, socketRef);
    });

    // For recurring fetch
    socketRef.on("recurringChartData", (data) => {
      // if (updateRecurringChartBool) {
      updateRecurringChart(
        data.data,
        chartToBuild,
        type,
        socketRef,
        updateRecurringChartBool,
        chart
      );

      fetching = false;
      // }
    });

    // fetchData(interval, symbol, type, chartToBuild);

    // To add Inifinite History
    chart.timeScale().subscribeVisibleLogicalRangeChange((logicalRange) => {
      if (logicalRange.from < 10) {
        // load more data
        const numberBarsToLoad = 50 - logicalRange.from;
        console.log("numberBarsToLoad", numberBarsToLoad, !fetching);

        if (!fetching) {
          // setTimeout(() => {
          socketRef.emit("requestRecurringChartData", {
            numberOfBars: Math.floor(numberBarsToLoad),
            currencyPair: symbol,
            interval: originalInterval,
            type,
          });

          fetching = true;
          // }, 1000);
        }
        // setTimeout(() => {
        //   updateRecurringChartBool = true;
        // }, 1000); // add a loading delay
      }
    });
    // Function to update the chart
    const updateChart = (objectData) => {
      const { currencyPair, data, interval } = objectData;
      // {currencyPair: 'OANDA:GBP_USD', interval: '1', data: Array(69)} data format

      const updatedData = data
        .filter((item) => item.time >= roundDownToInterval(interval))
        .map((item) => ({
          time: timeToTzWithDateFnsTz(item.time / 1000).zonedTime,
          open: item.open,
          high: item.high,
          low: item.low,
          close: item.close,
        }));

      var realTimeData_ = [...realTimeData, ...updatedData];
      // console.log(realTimeData_, "UpdatedData");
      chartToBuild.setData(realTimeData_);
      // chart.timeScale().fitContent();
      // chart.timeScale().scrollToPosition(5);
    };
    //
    socketRef.on("ohclData", (data: SocketData) => {
      // updateChart(data);
      // Dispatch to Redux store if needed
      // store.dispatch(setForexData(data)); // Uncomment and adjust if needed
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove(); // Clean up chart on unmount
    };
  }, [theme, location.search]);

  const styles = {
    errorContainer: {
      display: "flex",
      padding: 100,
      height: window.innerHeight,
      backgroundColor: theme === "dark" ? "#000000" : "#ffffff",
      color: theme === "dark" ? "#ffffff" : "#000000",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
  };

  return (
    <>
      <div>
        <div
          id="container"
          ref={chartOuterContainerRef}
          style={error ? styles.errorContainer : { display: "none" }}
        >
          {errorMessage}
        </div>
        <div
          id="chartContainer"
          ref={chartContainerRef}
          style={error ? { display: "none" } : undefined}
        />
      </div>
    </>
  );
}

export default Chart;
