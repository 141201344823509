import SocketIOClient from "socket.io-client";
import { env } from "../devdata/const/env";

// Initialize socket connection
const socketRef = SocketIOClient(`${env.miansocketip}`, {
  withCredentials: true,
  auth: {
    apiKey: env.socketkey,
  },
  autoConnect: true,
});

var isconnected = false;

const connectSocket = () => {
  socketRef.connect();

  // Handle successful connection
  socketRef.on("connect", () => {
    console.log("Connected to server");
    isconnected = true;
  });

  // Handle disconnection
  socketRef.on("disconnect", () => {
    isconnected = false;
    console.log("Disconnected from server");
  });

  // Handle ping from server
  socketRef.on("ping", () => {
    isconnected = true;
    console.log("Hello from server");
  });

  // Handle connection errors
  socketRef.on("connect_error", (error) => {
    console.log("Connection error:", error.message || error);
  });

  // Handle reconnection attempts
  socketRef.on("reconnect_attempt", () => {
    console.log("Reconnecting...");
  });

  // Handle general errors from server
  socketRef.on("error", (error) => {
    console.error("Error received from server:", error.message || error);
  });
};

// General function to request OHLC data for any interval
const requestOHCLData = (pair, type, interval) => {
  const eventName = `requestOHCL:${interval}`;
  console.log(`Requesting ${interval} OHLC data for ${pair}`);
  socketRef.emit(eventName, { currencyPair: pair, type });
};

const requestInitialChartData = (
  currencyPair,
  interval,
  type,
  numberOfBars
) => {
  socketRef.emit("requestInitialChartData", {
    currencyPair,
    interval,
    type,
    numberOfBars,
  });
};

const requestRecurringChartData = (
  numberOfBars,
  currencyPair,
  interval,
  type
) => {
  socketRef.emit("requestRecurringChartData", {
    numberOfBars,
    currencyPair,
    interval,
    type,
  });
};

const getInitialChartData = () => {
  socketRef.on("initialChartData", (data) => {
    console.log("Initial chart data received:", data);
    return data;
  });
};

const getRecurringChartData = () => {
  socketRef.on("recurringChartData", (data) => {
    console.log("Recurring chart data received:", data);
    return data;
  });
};

// Functions to request OHLC data for specific intervals
function get1m(pair, type) {
  requestOHCLData(pair, type, "1");
}

function get5m(pair, type) {
  requestOHCLData(pair, type, "5");
}

function get15m(pair, type) {
  requestOHCLData(pair, type, "15");
}

function get30m(pair, type) {
  requestOHCLData(pair, type, "30");
}

function get60m(pair, type) {
  requestOHCLData(pair, type, "60");
}

function get1Day(pair, type) {
  requestOHCLData(pair, type, "1440");
}

function get1Week(pair, type) {
  requestOHCLData(pair, type, "10080");
}

function get1Month(pair, type) {
  requestOHCLData(pair, type, "43200");
}

// Function to unsubscribe from a room
const unsubscribeFromRoom = (room) => {
  socketRef.emit("unsubscribe", room);
};

export {
  connectSocket,
  socketRef,
  isconnected,
  get1m,
  get5m,
  get15m,
  get30m,
  get60m,
  get1Day,
  get1Week,
  get1Month,
  requestInitialChartData,
  requestRecurringChartData,
  getInitialChartData,
  getRecurringChartData,
  unsubscribeFromRoom,
};
