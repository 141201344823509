// eslint-disable-next-line
import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Chart } from "./components";
import { connectSocket, isconnected } from "./components/socket";

function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const theme = queryParams.get("theme") === "dark" ? "dark" : "light";
  const symbol = queryParams.get("symbol") || "OANDA:GBP_USD";
  const size = Number(queryParams.get("size")) || 10;
  const zoomIndex = Number(queryParams.get("zoomIndex")) || null;
  const interval = queryParams.get("interval") || 1;
  const type = queryParams.get("type") || "candlestick";
  let seconds = 0;

  switch (interval) {
    case "1":
      seconds = 1;
      break;
    case "5":
      seconds = 5;
      break;
    case "15":
      seconds = 15;
      break;
    case "30":
      seconds = 30;
      break;
    case "60":
      seconds = 60;
      break;
    case "D":
      seconds = 24 * 60 * 60; // 1 day in seconds
      break;
    case "W":
      seconds = 7 * 24 * 60 * 60; // 1 week in seconds
      break;
    case "M":
      seconds = 30 * 24 * 60 * 60; // 1 month (assuming 30 days) in seconds
      break;
    default:
      seconds = 1; // Default to 1 second if interval is not recognized
  }
  useEffect(() => {
    if (!isconnected) {
      connectSocket();
    }
  }, []);

  const props = {
    theme: theme,
    symbol: symbol,
    size: size,
    zoomIndex: zoomIndex,
    interval: seconds,
    type: type,
    originalInterval: interval,
  };

  return <Chart props={props} />;
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
